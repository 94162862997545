import { animateToggleHeight } from './utils';

export default function() {
  (function initOpeningPanel() {
    const blocks = document.querySelectorAll('.opening-panel');

    blocks.forEach(el => {
      el.querySelector('.opening-panel__toggle').addEventListener(
        'click',
        () => {
          if (el.classList.contains('opening-panel--open')) {
            animateToggleHeight(
              el.querySelector('.opening-panel__content'),
              true,
              500
            );
          } else {
            animateToggleHeight(
              el.querySelector('.opening-panel__content'),
              false,
              500
            );
          }
          el.classList.toggle('opening-panel--open');
        }
      );
    });
  })();
}
