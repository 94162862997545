import axios from 'axios'
import {CookiesService} from './CookiesService'

class Form {
  constructor(props) {
    this.form = props.form
    this.formId = this.form.getAttribute('data-id-form')
    this.contentAtr = this.form.getAttribute('data-form-content-atr') || 'data-form-final-content'
    this.contentContainer = document.querySelector(`[${this.contentAtr}="${this.formId}"]`)
    this.finalContent = this.contentContainer?.getAttribute('data-final-content') || '<h3>Спасибо! Мы скоро с вами свяжемся.</h3>'
    this.formData = null
    this.cookiesSrvice = new CookiesService()
    this.cookieData = this.form.querySelector('[data-cookie-form]')?.getAttribute('data-cookie-form') || null

    this.init()
  }

  /**
   * Инициирует обработчики.
   */
  init() {
    this.form.addEventListener('submit', (e) => {
      this.submitForm(e)
    })
  }

  /**
   * Отправляет форму.
   * Запрещает перезагрузку страницы при сабмите.
   * @param e
   */
  submitForm(e) {
    this.formData = new FormData()
    const inputs = this.form.querySelectorAll('[data-input-id]')
    inputs?.forEach((input) => {
      this.formData.append(input.getAttribute('data-input-id'), input.value)
    })

    this.formData.append('id', this.formId)
    e.preventDefault() // заглушаем перезагрузку страницы

    axios.post('https://mt.media/api/forms/', this.formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((result) => {
      if (result.data.success) {
        this.makeSuccessDom()
      }

      if (this.cookieData) {
        const cookie = this.cookieData.split(':')
        const expires = new Date(Date.now())
        expires.setDate(expires.getDate()+90)
        this.cookiesSrvice.setCookie(cookie[0], cookie[1], {
          expires,
        })
      }

      return result.data
    })
  }

  /**
   * Меняет контент блока.
   */
  makeSuccessDom() {
    this.contentContainer.innerHTML = this.finalContent
  }

}

/**
 * Инициализирует формы.
 */
export function initForms() {
  const forms = document.querySelectorAll('[data-id-form]') || null
  forms?.forEach((form) => {
    new Form({form})
  })
}
