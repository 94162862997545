import {animateToggleHeight} from "./utils";

export default function () {

  (function initFilters() {
    const blocks = document.querySelectorAll('[data-filter]');

    blocks.forEach((el) => initFilter(el))

  })();


}

function initFilter(block) {
  const tabs = block.querySelectorAll('[data-filter-tab]')
  const blocks = block.querySelectorAll('[data-filter-block]')

  tabs.forEach((el) => {
    el.addEventListener('click', () => {
      const data = el.dataset.filterTab
      const contentBlock = block.querySelector('[data-filter-block="' + data + '"]')
      if (el.classList.contains('filter__tab--active') && contentBlock) {
        animateToggleHeight(contentBlock, true, 200);
      } else {
        const activeContentBlock = block.querySelector('.filter__block--active')
        const activeTab = block.querySelector('.filter__tab--active')
        if (activeContentBlock) {
          animateToggleHeight(activeContentBlock, true, 200);
          activeContentBlock.classList.remove('filter__block--active')
        }
        if (activeTab) {
          activeTab.classList.remove('filter__tab--active');
        }
        if (contentBlock) {
          animateToggleHeight(contentBlock, false, 200);
        }
      }
      el.classList.toggle('filter__tab--active');
      if (contentBlock) {
        contentBlock.classList.toggle('filter__block--active');
      }

    })
  })
}