import {Modal} from '../classes/Modal'
import {CookiesService} from '../classes/CookiesService'

export function initSubscriptionModal() {
  const modal = document.querySelector('[data-modal-subscription]')
  const modalService = new Modal({ modal })
  const cookieService = new CookiesService()
  const closerCookie = cookieService.getCookie('SHOW_SUB_POPUP')
  const subscribeCookie = cookieService.getCookie('SUBSCRIBE_DATA')
  if (!closerCookie && !subscribeCookie) {
    modalService.showModal()
  }
}
