import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Swiper from 'swiper';
import Player from '@vimeo/player';

export default function() {
  (function makeMainScreeSlider(){
    const msSlider = document.querySelector('.js-main-slider');
    const videoInputs = document.querySelectorAll('.js-video-id');

    if (!msSlider || !videoInputs) return;

    const buttonsHolder = document.querySelector('.main-screen__sound-toggle');
    let videosArray = {};
    let i = 0;
    let soundFlag = false;
    let isCarousel = false;


    videoInputs.forEach(function (element) {
      videosArray[i] = element.dataset.id;
      i++;
    });

    function fetchVideoAndPlay(url, video) {
      fetch(url)
          .then(response => response.blob())
          .then(blob => {
            video.srcObject = blob;
            return video.play();
          })
          .then(_ => {
            // Video playback started ;)
          })
          .catch(e => {
            // Video playback failed ;(
          })
    }

    const videoInstanceArray = [];

    const slides = msSlider.querySelectorAll('.swiper-slide');
    const sliderInstance = new Swiper(msSlider,{
      // initialSlide: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade:'true',
      },
      navigation:{
        prevEl: '#swiper-prev',
        nextEl: '#swiper-next',
      },
      pagination: {
        el: '.main-screen__pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (slides[index].dataset.slideTitle || `Трейлер ${index+1}`) + '</span>';
        },
      },
      touchRatio: 0,
      on: {
        init: function(){

          if (slides.length == 1) {
            isCarousel = true;
          }
          msSlider.querySelector('.main-screen__slider-wrapper').classList.add('main-screen__slider-wrapper--playable');
          slides.forEach((slide, index)=>{
            let player = new Player(slide,{
              id: videosArray[index],
              muted: true,
              controls: false,
              // background: true,
              loop: isCarousel,
              autoplay: false,
            });
            videoInstanceArray.push(player);
            player.on('play', function() {

            });
            player.on('ended', function(){
              if (index != slides.length - 1) {
                sliderInstance.slideNext();
              } else {
                sliderInstance.slideTo(0);
              }


            });

            videoInstanceArray[0].play();
          });

        },

        slideChange: function(){
          let currentVideo = videoInstanceArray[this.realIndex];
          currentVideo.play();
          if (soundFlag) {
            currentVideo.setMuted(false).then(function(muted) {
              currentVideo.setVolume(0.5);
            })
          } else {
            currentVideo.setMuted(true).then(function(muted) {})
          }
        }
      }
    });

    if (window.innerWidth > 1024 || document.querySelector('.main-screen--new')) {
      buttonsHolder.addEventListener('click', ()=>{
        let currentVideo = videoInstanceArray[sliderInstance.realIndex];
        buttonsHolder.classList.toggle('main-screen__sound-toggle--sound-on');
        soundFlag = buttonsHolder.classList.contains('main-screen__sound-toggle--sound-on');
        if (soundFlag) {
          currentVideo.setMuted(false).then(function(muted) {
            currentVideo.setVolume(0.5);

          })
        } else {
          currentVideo.setMuted(true).then(function(muted) {

          })
        }
      })
    }
  })();
}
