export const mql = window.matchMedia('(min-width: 1200px)');
export const remRate = parseFloat(window.getComputedStyle(document.querySelector('html')).fontSize) / 16;

export const animate = function ({duration, draw, endEvent = () => {}}) {
  let start = performance.now()

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration
    if (timeFraction > 1) timeFraction = 1
    if (timeFraction < 0) timeFraction = 0

    let progress = timeFraction

    draw(progress)

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    } else {
      endEvent()
    }
  });
}
export const animateToggleHeight = function(wrap, open, duration, callback) {
  let height = 0;

  if (!callback) {
    callback = () => {
    }
  }
  if (open) {
    //закрытие
    wrap.style.display = 'block'
    height = wrap.offsetHeight
    wrap.style.overflow = 'hidden'
    duration = duration || parseFloat(height) * 0.4
    animate({
      duration: duration,
      draw: function draw(progress) {
        wrap.style.height = (1 - progress) * height + 'px'
      },
      endEvent: function endEvent() {
        wrap.removeAttribute('style')
        wrap.style.display = 'none'
        callback()
      }
    });
  } else {
    // открытие
    wrap.removeAttribute('style')
    wrap.style.display = 'block'
    height = wrap.offsetHeight
    wrap.style.height = '0'
    wrap.style.overflow = 'hidden'
    duration = duration || parseFloat(height) * 0.4
    animate({
      duration: duration,
      draw: function draw(progress) {
        wrap.style.height = progress * height + 'px'
      },
      endEvent: function endEvent() {
        wrap.style.overflow = ''
        wrap.style.height = ''
        callback()
      }
    })
  }
}