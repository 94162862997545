import Swiper from 'swiper';
import {mql} from "./utils";
import {remRate} from "./utils";
import {Autoplay, EffectFade} from 'swiper/dist/js/swiper.esm'

export default function() {

  (function initNewsMainSlider(){
    const sliderHolder = document.querySelector('.news--main .news__slider');

    if (window.innerWidth > 767 || !sliderHolder) return;

    const sliderInstance = new Swiper(sliderHolder,{
      pagination: {
        el: '.news__slider-pagination',
        dynamicBullets: true,
        dynamicMainBullets: 3,

      }
    });
  })();

  (function initPromoMainSlider(){
    const sliderHolder = document.querySelector('.promo__slider');

    if (window.innerWidth > 1024 || !sliderHolder) return;

    const sliderInstance = new Swiper(sliderHolder,{
      pagination: {
        el: '.promo__slider-pagination',
        dynamicBullets: true,
        dynamicMainBullets: 3,
      }
    });
  })();


  (function initAboutMobileSlider(){
    const sliderHolder = document.querySelector('.triple-cols');

    if (window.innerWidth > 1024 || !sliderHolder) return;

    const container = sliderHolder.querySelector('.triple-cols__content');
    const wrapper = sliderHolder.querySelector('.triple-cols__holder');
    const slides = sliderHolder.querySelectorAll('.triple-cols__col');

    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    slides.forEach((slide)=>{
      slide.classList.add('swiper-slide');
    });

    const sliderInstance = new Swiper(container,{
      pagination: {
        el: '.triple-cols__pagination',
        dynamicBullets: true,
        dynamicMainBullets: 1,

      }
    });
  })();

  (function initDoubleSliders(){
    const sliderHolder = document.querySelectorAll('.double-columns');

    if (!sliderHolder) return;
    if (window.innerWidth > 767) return;

    sliderHolder.forEach((slider)=>{
      slider.classList.add('swiper-container');
      slider.querySelector('.double-columns__columns').classList.add('swiper-wrapper');
      slider.querySelector('.double-columns__left').classList.add('swiper-slide');
      slider.querySelector('.double-columns__right').classList.add('swiper-slide');
      let pagination = slider.querySelector('.double-columns__pagination');

      const sliderInstance = new Swiper(slider,{
        pagination: {
          el: pagination

        }
      });
    })


  })();

  (function initProjectPhotoSliders(){
    const sliderHolder = document.querySelectorAll('.project__images-slider');

    if (!sliderHolder) return;
    if (window.innerWidth > 767) return;

    sliderHolder.forEach((slider)=>{
      slider.classList.add('swiper-container');
      // slider.querySelector('.double-columns__columns').classList.add('swiper-wrapper');
      // slider.querySelector('.double-columns__left').classList.add('swiper-slide');
      // slider.querySelector('.double-columns__right').classList.add('swiper-slide');

      const sliderInstance = new Swiper(slider,{
        slidesPerView: 'auto',
        spaceBetween: 20,
      });
    })


  })();

  const mainPhotosSlider = new Swiper('.main-screen-photos', {
    modules: [Autoplay, EffectFade],
    loop: true,
    speed: 700,
    effect: 'fade',
    autoplay: {
      delay: 4000,
    },
  })
};



