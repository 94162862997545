import {CookiesService} from './CookiesService'

export class Modal {
  constructor(props) {
    this.modal = props.modal || null
    this.tonerClass = 'b-modal-full'
    this.modalAtr = 'data-modal-full'
    this.openerAtr = 'data-modal-full-open'
    this.closerAtr = 'data-modal-full-closer'
    this.activeClass = 'b-modal-full--active'
    this.openers = null
    this.closers = null
    this.cookieData = this.modal.querySelector('[data-cookie]')?.getAttribute('data-cookie') || null
    this.cookieService = new CookiesService()

    if (this.modal) {
      this.initModal()
    }
  }

  initModal() {
    this.openers =
      document.querySelectorAll(`[${this.openerAtr}="${this.modal.getAttribute(this.modalAtr)}"]`)
      || null

    this.openers?.forEach((i) => {
      i.addEventListener('click', () => {
        this.showModal()
      })
    })

    this.closers = this.modal.querySelectorAll(`[${this.closerAtr}]`)

    this.closers?.forEach((c) => {
      c.addEventListener('click', () => {
        this.hideModal()
      })
    })

    this.modal.addEventListener('click', (e) => {
      if (e.target.classList.contains(this.tonerClass)) {
        this.hideModal()
      }
    })
  }

  /**
   * Показывает модальное окно.
   */
  showModal() {
    this.modal.classList.add(this.activeClass)
  }

  /**
   * Скрывает модальное окно.
   */
  hideModal() {
    this.modal.classList.remove(this.activeClass)

    if (this.cookieData) {
      const cookie = this.cookieData.split(':')
      const expires = new Date(Date.now())
      expires.setDate(expires.getDate()+1)
      this.cookieService.setCookie(cookie[0], cookie[1], {
        expires,
      })
    }
  }
}
