import {getCookie} from "../components/getCookies";


export class FavoriteUpdater {
  constructor() {
    this.cookieName = 'mt-favorites'
    this.initButtonsActions()
  }

  initButtonsActions() {
    const buttons = document.querySelectorAll('[data-favorites-button]')

    buttons.forEach((i) => {
      i.addEventListener('click', (e) => {
        e.preventDefault()
        i.classList.contains('project-card__favorites-button')
          ? i.classList.toggle('project-card__favorites-button--active')
          : i.classList.toggle('main-screen__favorites-button--active')
        this.updateCookie(i.getAttribute('data-favorites-button'))
      })
    })
  }

  /**
   * Функция для запуска обновления товара в избранном.
   * Обновляет куки.
   * Обновляет дом элементы.
   * @param itemId
   */
  setFavFromDom(itemId) {
    this.updateCookie(itemId)
    this.changeButtonsOnPage(itemId)
    this.setMainIcon()
  }

  /**
   * Обновляет куку.
   * Либо добавляет, либо удаляет элемент.
   * @param itemId
   * @returns {any}
   */
  updateCookie(itemId) {
    const actualCookies = this.getCookieFav()

    if (actualCookies) {
      const indexCheck = actualCookies.includes(`${itemId}`, 0) || actualCookies.includes(+itemId, 0)
      if (indexCheck) {
        const delIndex = []
        actualCookies.forEach((value, index) => {
          if (+value === +itemId || value === null) {
            delIndex.push(index)
          }
        })
        console.log(delIndex)
        if (delIndex.length) { // тут проблема
          delIndex.forEach((value, index) => {
            actualCookies.splice(value, 1)
            // delIndex[index+1] = delIndex[index+1] - 1
          })
        }
      } else {
        actualCookies.push(itemId)
      }
      document.cookie = `mt-favorites=${JSON.stringify(actualCookies)}; max-age=${3600*24*30*12}; path=/`
    } else {
      document.cookie = `mt-favorites=${JSON.stringify([itemId])}; max-age=${3600*24*30*12}; path=/`
    }

    console.log(JSON.parse(getCookie(this.cookieName)))

    return JSON.parse(getCookie(this.cookieName))
  }

  /**
   * Меняет кнопочки на странице в заивисмости от данных в куках
   * @param itemId
   */
  changeButtonsOnPage(itemId) {
    const actualCookies = this.getCookieFav()
    const allButtons = document.querySelectorAll(`[data-fav-id="${itemId}"]`)
    const indexCheck = actualCookies.includes(itemId, 0)
    allButtons.forEach((i) => {
      indexCheck ? i.classList.add('m-button_square_active') : i.classList.remove('m-button_square_active')
    })
  }

  /**
   * Получает куку fav
   * @returns {any|string}
   */
  getCookieFav() {
    const cookieData = getCookie(this.cookieName)
    return cookieData && JSON.parse(cookieData) || ''
  }

  /**
   * Обновляет иконку в шапке
   */
  setMainIcon() {
    const favCount = this.getCookieFav().length
    const iconFavorite = document.querySelector('[data-popup="favorite"]')
    iconFavorite.setAttribute('data-count', favCount)
  }

  /**
   * Проверяет, есть ли товар в избранном
   * @param itemId
   * @returns {boolean}
   */
  checkItemInFav(itemId) {
    const actualCookies = this.getCookieFav()
    return actualCookies.includes(`${itemId}`, 0) || actualCookies.includes(+itemId, 0)
  }
}
