export default class goldenCollections {
  constructor() {
    this.goldenItems = document.querySelectorAll('.golden-collections-item');
    this.init();
  }

  init() {
    this.goldenItems.forEach(item => {
      item.addEventListener('click', e => {
        const img = e.target
          .closest('.golden-collections__item')
          .querySelector('.golden-collections-item__images');

        this.goldenItems.forEach(pic => {
          pic.classList.remove('_show');
        });

        img.classList.remove('_show');
        setTimeout(() => {
          img.classList.add('_show');
        }, 350);

        this.goldenItems.forEach(i => {
          i.classList.remove('_active');
        });
        item.classList.add('_active');
      });
    });
  }
}
