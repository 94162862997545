export class CookiesService {
  /**
   * Получает куку по имени.
   * @param name
   * @returns {string|undefined}
   */
  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  /**
   * Устанавливает куку по имени, также устанавливает разные опции.
   * @param name
   * @param value
   * @param options
   */
  setCookie(name, value, options = {}) {
    options = {
      path: '/',
      // при необходимости добавьте другие значения по умолчанию
      ...options,
    }

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString()
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey
      let optionValue = options[optionKey]
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue
      }
    }

    document.cookie = updatedCookie
  }

  /**
   * Удаляет куку.
   * @param name
   */
  deleteCookie(name) {
    this.setCookie(name, '', {
      'max-age': -1,
    })
  }
}
