import tech from './components/tech.js';
import sliders from './components/sliders.js';
import mainScreenSlider from './components/main-screen.js';
import openingPanel from './components/opening-panel';
import filter from './components/filter';
import goldenCollections from './components/golden-collections';
import Popups from './components/popup.js';
import {FavoriteUpdater} from "./classes/favoriteUpdater";
import {initForms} from "./classes/Form";
import {initSubscriptionModal} from './components/subscriptionModal'

mainScreenSlider();
sliders();
tech();
openingPanel();
filter();
new goldenCollections();

window.addEventListener('load', () => {
  new Popups();
  new FavoriteUpdater()
  initForms()
  initSubscriptionModal()
});
